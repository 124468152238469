import React from "react";
import { Row, Col } from "antd";

import SEO from "../components/seo";
import {
  SliderClients,
  Layout,
  ContentServices,
  ContainerContentFixed,
  ContainerBackgroundWhite,
  CtaPrimary,
  CtaSecondary,
} from "../components";

import { HeadingSection } from "../components/headings";
import { Colors } from "../components/constants";
import { LogoIconBanner } from "../components/logos";
import { InternalLink, InternalLinkTypes } from "../components/links";
import { Testimonials } from "../components/testimonials";

const IndexPage = () => (
  <Layout pageLink={InternalLinkTypes.Home}>
    <SEO
      title="Automation and Algorithms for Energy Markets"
      description={
        "Specialising in Automation and Algorithms for Energy Markets. Delivering real-time services for the Australian National Electricity Market since 2014, Live autobidding since 2021."
      }
    />
    <div
      style={{
        backgroundColor: "#f4f4f4",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          zIndex: 2,
          textAlign: "center",
          position: "relative",
          padding: "2rem 10%",
        }}
      >
        <ContainerContentFixed style={{ maxWidth: "1024px" }}>
          <div style={{ color: Colors.DARK_BLUE }}>
            <h1
              style={{
                color: "inherit",
                fontSize: "3.57rem",
                fontWeight: "bold",
                margin: "0",
              }}
            >
              Automation and Algorithms
            </h1>

            <h1
              style={{
                color: "inherit",
                fontSize: "2.71rem",
                margin: "0",
                fontWeight: "100",
              }}
            >
              web services for Energy Markets
            </h1>
          </div>
          <Row
            style={{
              margin: "auto",
              marginBottom: "32px",
              color: "inherit",
              fontSize: "1.42rem",
            }}
            
          >
            <Col xs={24} lg={8}>
              <h2
                style={{
                  fontWeight: "bold",
                  marginTop: "24px",
                  marginBottom: "16px",
                  color: "inherit",
                  fontSize: "inherit",
                }}
              >
                Automated Bidding
              </h2>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                  fontSize: "inherit",
                  lineHeight: "2rem",
                  fontSize: '18px'
                }}
              >
                <li>cooptimise FCAS and energy</li>
                <li>ESS, baseload and renewables</li>
                <li>priceband volume allocation</li>
                <li>benchmarking and reporting</li>
              </ul>
            </Col>
            <Col xs={24} lg={8}>
              <h2
                style={{
                  fontWeight: "bold",
                  marginTop: "24px",
                  marginBottom: "16px",
                  color: "inherit",
                  fontSize: "inherit",
                }}
              >
                Machine Learning Forecasts
              </h2>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                  fontSize: "inherit",
                  lineHeight: "2rem",
                  fontSize: '18px'
                }}
              >
                <li>predispatch prices <br/><span style={{ fontSize: '16px'}}>(updated 1/2 hourly)</span></li>
               
                <li>week ahead prices <br/><span style={{ fontSize: '16px'}}>(updated hourly)</span> </li>
                
              </ul>
            </Col>
            <Col xs={24} lg={8}>
              <h2
                style={{
                  fontWeight: "bold",
                  marginTop: "24px",
                  marginBottom: "16px",
                  color: "inherit",
                  fontSize: "inherit",
                }}
              >
                Algorithmic Data
              </h2>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                  fontSize: "inherit",
                  lineHeight: "2rem",
                  fontSize: '18px'
                }}
              >
                <li>battery market performance</li>                
                <li>4sec causer pays and FI data</li>
                <li>regulation FCAS liability</li>
                <li>expected generation profiles</li>
              </ul>
            </Col>
          </Row>
        </ContainerContentFixed>
      </div>

      <LogoIconBanner
        style={{
          marginTop: "-700px",
          marginRight: "-580px",
          marginBottom: "-520px",
          float: "right",
          position: "relative",
          zIndex: 1,
          width: "1200px",
          overflow: "hidden",
          opacity: "0.9",
        }}
      />
    </div>
    <div
      style={{
        textAlign: "center",
        padding: "8px 10%",
        background: Colors.DARK_BLUE,
        color: "#fff",
        fontSize: "1.4rem",
      }}
    >
      pd4castr price forecast service for predispatch and week ahead is live!&nbsp;
      <CtaSecondary>
        <InternalLink url={InternalLinkTypes.ServicePd4castr}>
          LEARN MORE
        </InternalLink>
      </CtaSecondary>
    </div>
    <ContainerBackgroundWhite
      style={{
        textAlign: "center",
        padding: "0 10%",
        paddingTop: "2rem",
      }}
    >
      <HeadingSection>Our Clients</HeadingSection>
      <SliderClients rows={1} />
    </ContainerBackgroundWhite>
    <div
      style={{
        backgroundColor: Colors.LIGHT_GRAY,
        textAlign: "center",
        padding: "2rem 10%",
      }}
    >
      <HeadingSection>Our Services</HeadingSection>
      <div>
        <ContentServices />
      </div>
    </div>

    <div
      style={{
        textAlign: "center",
        padding: "2rem 10%",
        background: Colors.LIGHTER_BLUE,
      }}
    >
      <Testimonials />
      <br />
      <h4
        style={{
          fontWeight: "500",
          fontSize: "1.3rem",
          lineHeight: "3rem",
          color: Colors.DARK_BLUE,
        }}
      >
        Contact us for more information or trial of our services
      </h4>
      <CtaPrimary style={{ minWidth: "160px", fontWeight: "bold" }}>
        <InternalLink url={InternalLinkTypes.Contact}>CONTACT US</InternalLink>
      </CtaPrimary>
    </div>
  </Layout>
);

export default IndexPage;
